.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body {
  background-color: #ffffff;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* App.css */

.statusIcon {
  font-size: 2em;
  margin-top: 10px;
}
/* App.css */

.pageContainer {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.container {
  position: relative;
  height: 400px;
  border: 2px solid #006100;
}
.inputContainer {
  display: inline;
  width: 600px;
  flex-direction: column;
  align-items: center;
}
input.inputStyle {
  border: 1px solid gray;
  border-radius: 20px;
  margin:5px;
  width: 300px;
  transition: 0.3s;
}
input.inputStyle:hover {
  border: 1px solid black;
  border-radius: 20px;
  margin:5px;
  width: 300px;
  transition: 0.3s;
}
input.inputStyle:active {
  border: 1px solid black;
  border-radius: 20px;
  margin:5px;
  width: 300px;
  transition: 0.3s;
}
::placeholder {
  text-align: center; 
}

input,
button {
  padding: 10px;
  border-radius: 5px;
}
button.buttonStyle {
  border-radius: 20px;
  border: none;
  width: 130px;
  margin: 5px;
  transition: 0.3s;
}
button.buttonStyle:hover {
  background-color: gray;
}
button {
  background-color: black;
  color: white;
  cursor: pointer;
  border: none;
}
.warrantyon {
  border: 2px dashed #1FBF75;
  border-radius: 20px;
  margin: 5px;
  margin-top: 15px;
  padding: 10px;
}
.warrantyoff {
  border: 2px dashed red;
  border-radius: 20px;
  margin: 5px;
  margin-top: 15px;
  padding: 10px;
}
p.warrantyonTitle {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: bold;
  color: #1FBF75;
}
p.warrantyoffTitle {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: bold;
  color: red;
}
p.warrantynoTitle {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  color: black;
}
.warrantyno {
  border: 2px dashed black;
  border-radius: 20px;
  margin: 5px;
  margin-top: 15px;
  padding: 10px;
}
h2 {
  font-size: 30px;
  margin: 5px;
}
p.image {
  display: inline-block;
  width: 45%;
  background: black;
  padding: 5px;
  margin: 5px;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}
.productsDiv {
  display: inline-flex;
}
.productName{
  font-size: 20px;
  font-weight: bold;
  margin:0px;
}
.productDiv {
  margin: 5px;
  padding: 10px;
  border: 1px dashed black;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
p.inputLabel {
  font-size: 12px;
  color: black;
  margin: 0px;
}
#signatureDiv {
  border-radius: 10px;
  background-color: white;
  border: 3px solid #00000071;
  overflow: hidden;
  margin-top: 5px;
}
.form-group {
  margin-bottom: 15px;
}
.form-control {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 25px;
  margin-top: 4px;
}
.mobileInput{
  width: 250px;
  height: 35px;
  border-radius: 20px;
  text-align: center;
  border: 1px solid #BDBDBD;
  margin: 5px;
  margin-bottom: 15px;
  -webkit-box-shadow: 0px 7px 20px -16px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 7px 20px -16px rgba(0,0,0,0.75);
  box-shadow: 0px 7px 20px -16px rgba(0,0,0,0.75);
}
.productType{
  margin: 5px;
  font-size: 13px;
}
hr.style-two {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}
.productDiv::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
}

.productDiv:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.productDiv:hover::before {
  transform: scale(1.2);
}

.productDiv:hover::after {
  transform: translate(50%, 50%);
}