.step-indicator-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .steps {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: lightgrey;
    margin: 0 10px;
    position: relative;
  }
  
  .step.active {
    background-color: #4caf50;
    color: white;
  }
  
  .step-number {
    font-size: 18px;
    font-weight: bold;
  }
  
  .step-label {
    font-size: 12px;
    margin-top: 5px;
  }
  
  .buttons {
    display: flex;
    justify-content: space-between;
    width: 200px;
  }
  
  button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  button:disabled {
    background-color: lightgrey;
    cursor: not-allowed;
  }